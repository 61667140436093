<template>
  <div class="container">
    <PageTitle>{{ $t("pageTitle.scoreReport") }}</PageTitle>
    <hr />
    <div class="row context">
      <div class="col-md-4">
        <div class="scorereport">
          <a
            href="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/Student%2C+Ivy-way++TPO2.pdf"
            target="_blank"
          >
            <img src="@/assets/scorereport.png" alt="scorereport" />
          </a>
        </div>
      </div>
      <div class="col-md-8">
        <ul>
          <li>
            <span class="text-success"
              ><i class="fas fa-check-square"></i
            ></span>
            {{ $t("toefl.PDF.line1") }}
          </li>
          <li>
            <span class="text-success"
              ><i class="fas fa-check-square"></i
            ></span>
            {{ $t("toefl.PDF.line2") }}
          </li>
          <li>
            <span class="text-success"
              ><i class="fas fa-check-square"></i
            ></span>
            {{ $t("toefl.PDF.line3") }}
          </li>
          <li>
            <span class="text-success"
              ><i class="fas fa-check-square"></i
            ></span>
            {{ $t("toefl.PDF.line4") }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('toefl.PDF.line4')"
              placement="top"
            >
              <el-button type="text">
                <b class="text-warning" style="font-size:18px"
                  ><i class="fas fa-question-circle"></i
                ></b>
              </el-button>
            </el-tooltip>
          </li>
        </ul>
        <div class="text-center" style="margin-top:40px">
          <a
            href="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/Student%2C+Ivy-way++TPO2.pdf"
            target="_blank"
            style="margin-right:20px"
          >
            <el-button type="success" plain>
              {{ $t("toefl.PDF.buttonView") }}
            </el-button>
          </a>
          <template v-if="hasExam">
            <el-button
              v-if="hasNoUnlockNumber"
              type="success"
              @click="() => unlockReport('unlockNumber')"
            >
              {{ $t("toefl.PDF.buttonUnlockByPlanNumber", { unlockNumber: userUnlockNumber }) }}
            </el-button>
            <el-button v-else type="success" @click="() => unlockReport('coin')">
              {{ $t("toefl.PDF.buttonUnlock") }}
            </el-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: " TestAdmit Test Taker Score Report - " + this.CompanyName
    };
  },

  components: { PageTitle },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    hasNoUnlockNumber() {
      return this.userUnlockNumber > 0;
    },
    userUnlockNumber() {
      return this.$store.getters["user/getProfile"]?.unlock_print_number || 0;
    },
    hasExam() {
      return this.$route.query.id !== undefined;
    }
  },
  watch: {},
  methods: {
    async checkIsUnlockStatus() {
      const { print_pdf: examPrintPdfIsUnlock } = await TOEFL.getExamPrintPdfIsUnlock(
        this.$route.query.id
      );
      return examPrintPdfIsUnlock;
    },
    async payForPDF() {
      await TOEFL.payForPDF(this.$route.query.id);
    },
    async payByUnlockNumberForPDF() {
      await TOEFL.payByUnlockNumberForPDF(this.$route.query.id);
    },
    async unlockReport(type) {
      if (await this.checkIsUnlockStatus()) {
        this.$router.push({
          name: "ResultPDF",
          query: this.$route.query
        });
        return;
      }

      const getQuestionText = () => {
        switch (type) {
          case "coin":
            return this.$t("toefl.PDF.unlockQuestion");
          case "unlockNumber":
            return this.$t("toefl.PDF.unlockQuestionByNumber");
        }
      };
      try {
        await this.$confirm(getQuestionText(type), this.$t("alert.confirm"), {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        });
      } catch (e) {
        this.$message({
          type: "info",
          message: this.$t("alert.cancel") + "!"
        });
        return;
      }

      try {
        switch (type) {
          case "coin":
            await this.payForPDF();
            break;
          case "unlockNumber":
            await this.payByUnlockNumberForPDF();
            break;
        }
        this.$message({
          type: "success",
          message: "Success!"
        });
        this.$router.push({
          name: "ResultPDF",
          query: this.$route.query
        });
      } catch (e) {
        // catch
      }
    }
  }
};
</script>

<style scoped>
.context {
  margin-top: 24px;
}

ul {
  list-style: none;
  font-size: 24px;
  font-weight: 700;
}
.scorereport {
  width: 100%;
}
.scorereport img {
  width: 100%;
  border: 2px solid #ccc;
}
</style>
